import React, { useEffect, useRef, useState } from 'react';
import Hls from 'hls.js';
import { Spin } from 'antd';

// eslint-disable-next-line react/prop-types
function CameraPreview({ hlsUrl }) {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [loading, setLoading] = useState(true); // Для отслеживания состояния загрузки
  const [hasError, setHasError] = useState(false); // Для отслеживания ошибок загрузки
  const [retryCount, setRetryCount] = useState(0); // Счётчик попыток повторной загрузки
  const [isLoaded, setIsLoaded] = useState(false); // Флаг успешной загрузки
  const hlsRef = useRef(null); // Для контроля над текущим HLS-потоком

  const captureFrame = () => {
    const canvas = canvasRef.current;
    const video = videoRef.current;

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    const ctx = canvas.getContext('2d');
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

    // Превращаем canvas в изображение
    const imageDataUrl = canvas.toDataURL('image/jpeg');
    setImageSrc(imageDataUrl);
    setLoading(false); // Останавливаем спиннер после загрузки
    setIsLoaded(true); // Помечаем, что загрузка успешна
  };

  const loadVideo = () => {
    // Отменяем предыдущую загрузку, если есть
    if (hlsRef.current) {
      hlsRef.current.destroy();
    }

    if (Hls.isSupported() && hlsUrl) {
      const hls = new Hls();
      hls.loadSource(hlsUrl);
      hls.attachMedia(videoRef.current);
      hlsRef.current = hls; // Сохраняем ссылку на текущий поток
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        videoRef.current
          .play()
          .then(() => {
            setTimeout(() => {
              videoRef.current.pause();
              captureFrame();
              if (hls) {
                hls.destroy(); // Останавливаем поток
              }
              setHasError(false); // Сброс ошибки
              setLoading(false); // Сброс загрузки
              setRetryCount(0); // Сброс счётчика попыток
              setIsLoaded(true); // Устанавливаем флаг успешной загрузки
            }, 1000); // Захват кадра через 1 секунду
          })
          .catch((error) => {
            console.error('Ошибка воспроизведения:', error);
            setHasError(true);
            setLoading(false); // Состояние ошибки
            setIsLoaded(false); // Отметим, что загрузка не завершилась
          });
      });
    }
  };

  useEffect(() => {
    // Если уже загружено, не делаем новых запросов
    if (isLoaded) return;

    setLoading(true);
    setHasError(false);

    const timeoutId = setTimeout(() => {
      if (loading) {
        console.error('Время ожидания истекло');
        setHasError(true); // Устанавливаем состояние ошибки, если время ожидания истекло
        setLoading(false);
        setIsLoaded(false); // Сбрасываем флаг успешной загрузки
      }
    }, 5000); // Таймаут 5 секунд

    loadVideo(); // Загружаем видео

    return () => {
      clearTimeout(timeoutId); // Очищаем таймаут при размонтировании компонента
      if (hlsRef.current) {
        hlsRef.current.destroy(); // Останавливаем текущий HLS-поток
      }
    };
  }, [hlsUrl, retryCount, isLoaded]);

  // Автоматическая повторная попытка при ошибке
  useEffect(() => {
    // Если уже загружено или превысили лимит попыток, не делаем повторные запросы
    if (hasError && retryCount < 3 && !isLoaded) {
      const retryTimeout = setTimeout(() => {
        console.log('Повторная попытка загрузки...');
        setRetryCount(retryCount + 1); // Увеличиваем счётчик попыток
        setHasError(false);
        setLoading(true);
        setIsLoaded(false); // Сбрасываем флаг успешной загрузки
      }, 3000); // Повторная попытка через 3 секунды

      return () => clearTimeout(retryTimeout); // Очищаем таймаут при размонтировании компонента
    }
  }, [hasError, retryCount, isLoaded]);

  return (
    <div className="relative flex w-full min-xs:h-[100px] min-sm:h-[200px] min-md:h-[200px] min-lg:h-[200px] min-xl:min-h-[200px]  min-2xl:min-h-[100px]">
      {/* Видео скрыто, так как нужно только для захвата кадра */}
      <video ref={videoRef} muted playsInline style={{ display: 'none' }} />
      <canvas ref={canvasRef} style={{ display: 'none' }} />

      {/* Спиннер во время загрузки */}
      {loading && !hasError && (
        <div className="flex justify-center items-center w-full h-full">
          <Spin size="large" tip="Loading Preview..." />
        </div>
      )}

      {/* Обработка ошибки при загрузке превью */}
      {hasError && retryCount >= 3 && (
        <div className="relative w-full h-full">
          <img
            src="https://placehold.co/486x273?text=Error"
            alt="Camera Preview"
            className="w-full h-full object-cover filter brightness-50 bg-blend-normal"
            onError={() => setHasError(true)} // Обработка ошибки загрузки изображения
          />
          {/* Название камеры и метка "Live" */}
          <div className="absolute top-2 left-2 flex items-center space-x-2">
            {/* <div className="bg-primary text-white px-2 py-1 rounded text-xs font-bold shadow-lg">{cameraName}</div> */}
            <div className="bg-red-600 text-white px-2 py-1 rounded text-xs font-bold shadow-lg">Error</div>
          </div>
        </div>
      )}

      {/* Отображаем затемненное превью с меткой "Live" и названием камеры */}
      {!loading && !hasError && imageSrc && (
        <div className="relative w-full h-full pb-5">
          <img
            src={imageSrc}
            alt="Camera Preview"
            className="w-full h-full object-cover filter brightness-50 bg-blend-normal"
            onError={() => setHasError(true)} // Обработка ошибки загрузки изображения
          />
          {/* Название камеры и метка "Live" */}
          <div className="absolute top-2 left-2 flex items-center space-x-2">
            {/* <div className="bg-primary text-white px-2 py-1 rounded text-xs font-bold shadow-lg">{cameraName}</div> */}
            <div className="bg-green-600 text-white px-2 py-1 rounded text-xs font-bold shadow-lg">Live</div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CameraPreview;
