import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main } from '../styled';
import { Cards } from '../../components/cards/frame/cards-frame';
// import { getKvsStreamUrl } from '../../utility/helpers';
// import VideoPlayer from '../../components/videoplayer/videoplayer';
// eslint-disable-next-line import/no-named-as-default
import VideoJS from '../../components/videoplayer/videojs';
import { getKvsStreamUrl } from '../../utility/helpers';

function BlankPage() {
  const dispatch = useDispatch();
  const [videoOptions, setVideoOptions] = useState({
    controls: true,
    sources: [
      {
        src: null,
        type: 'application/x-mpegURL',
      },
    ],
  });
  // const isLoading = useSelector((state) => state.cameras.loading);

  const cameras = useSelector((state) => state.cameras.cameras);
  useEffect(() => {
    // Отправляем действие при загрузке компонента
    const fetchStreamUrl = async (streamName) => {
      try {
        const url = await getKvsStreamUrl(streamName);
        console.log('Stream URL:', url);
        setVideoOptions({
          controls: true,
          autoplay: false,
          responsive: true,
          fluid: true,
          sources: [
            {
              src: url,
              type: 'application/x-mpegURL',
            },
          ],
        });
      } catch (error) {
        console.error('Error fetching stream URL', error);
      }
    };
    fetchStreamUrl('a');
  }, [dispatch]);
  // eslint-disable-next-line no-unused-vars
  console.log('Cameras:', cameras);
  const PageRoutes = [
    {
      path: 'index',
      breadcrumbName: 'Dashboard',
    },
    {
      path: '',
      breadcrumbName: 'Blank Page',
    },
  ];

  return (
    <>
      <PageHeader className="ninjadash-page-header-main" title="Test video" routes={PageRoutes} />
      <Main>
        <Row gutter={25}>
          <Col sm={24} xs={24}>
            <Cards headless>
              <h1>Live Stream</h1>
              {videoOptions.sources[0].src ? <VideoJS options={videoOptions} /> : <p>Loading stream...</p>}
            </Cards>
          </Col>
        </Row>
      </Main>
    </>
  );
}

export default BlankPage;
