import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

function CameraStream({ hlsUrl }) {
  const placeholderRef = useRef(null);
  const playerRef = useRef(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const checkHlsUrl = async () => {
      if (!hlsUrl) {
        setError('HLS URL отсутствует');
        return;
      }

      try {
        const response = await fetch(hlsUrl, {
          method: 'GET',
          headers: {
            Authorization: `Basic ${btoa('admin:V22n16m34')}`,
          },
        });

        if (!response.ok) {
          throw new Error('Не удалось получить поток HLS');
        } else {
          console.log('HLS URL доступен:', hlsUrl);
        }
      } catch (err) {
        setError(err.message);
      }
    };

    checkHlsUrl();
  }, [hlsUrl]);

  useEffect(() => {
    if (!playerRef.current && placeholderRef.current) {
      const placeholderEl = placeholderRef.current;

      const videoElement = placeholderEl.appendChild(document.createElement('video-js'));

      playerRef.current = videojs(videoElement, {
        controls: true,
        autoplay: true,
        preload: 'auto',
      });

      videojs.Vhs.xhr.onRequest = (options) => {
        options.beforeSend = (xhr) => {
          xhr.setRequestHeader('Authorization', `Basic ${btoa('admin:V22n16m34')}`);
        };
        console.log('Параметры запроса:', options);
        return options;
      };

      playerRef.current.src({
        src: hlsUrl,
        type: 'application/x-mpegURL',
      });

      videoElement.style.width = '100%';
      videoElement.style.height = '700px';
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, [hlsUrl]);

  if (error) {
    return <div>Ошибка: {error}</div>;
  }

  return <div ref={placeholderRef} />;
}

CameraStream.propTypes = {
  hlsUrl: PropTypes.string.isRequired,
};

export default CameraStream;
