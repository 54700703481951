import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import TestPage from '../../container/pages/TestPage';
import DashboardPage from '../../container/pages/DashboardPage';
import AlertsPage from '../../container/pages/AlertPage';
import CamerasPage from '../../container/pages/CamerasPage';
import SensorsPage from '../../container/pages/SensorsPage';
import MapPage from '../../container/pages/MapPage';
import EventsPage from '../../container/pages/EventsPage';
import VideoGptPage from '../../container/pages/VideoGptPage';
import PlansPage from '../../container/pages/PlansPage';
import ReportsPage from '../../container/pages/ReportsPage';
import TrainPage from '../../container/pages/TrainPage';
import VideoTestPage from '../../container/pages/VideoTest';
import UsersPage from '../../container/pages/UsersPage';
import Wizards from '../../container/pages/Wizards';
import CameraDetails from '../../container/pages/CameraDetails';

const BlankPage = lazy(() => import('../../container/pages/BlankPage'));

function PagesRoute() {
  return (
    <Routes>
      <Route index element={<BlankPage />} />
      <Route path="starter" element={<TestPage />} />
      <Route path="dashboard" element={<DashboardPage />} />
      <Route path="alerts" element={<AlertsPage />} />
      <Route path="videotest" element={<VideoTestPage />} />
      <Route path="cameras" element={<CamerasPage />} />
      <Route path="/cameras/:id" element={<CameraDetails />} />
      <Route path="users" element={<UsersPage />} />
      <Route path="wizards" element={<Wizards />} />
      <Route path="sensors" element={<SensorsPage />} />
      <Route path="map" element={<MapPage />} />
      <Route path="events" element={<EventsPage />} />
      <Route path="videogpt" element={<VideoGptPage />} />
      <Route path="plans" element={<PlansPage />} />
      <Route path="reports" element={<ReportsPage />} />
      <Route path="train" element={<TrainPage />} />
    </Routes>
  );
}

export default PagesRoute;
