import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';

function AddCameraForm({ onSubmit, form }) {
  const onFinish = (values) => {
    onSubmit(values);
    form.resetFields();
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Form.Item name="name" label="Camera Name" rules={[{ required: true, message: 'Please input the camera name!' }]}>
        <Input placeholder="Enter camera name" />
      </Form.Item>
      <Form.Item name="rtspLink" label="RTSP Link" rules={[{ required: true, message: 'Please input the RTSP link!' }]}>
        <Input placeholder="Enter RTSP link" />
      </Form.Item>
    </Form>
  );
}

AddCameraForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
};

export default AddCameraForm;
