const actions = {
  GET_CAMERAS_START: 'GET_CAMERAS_START',
  GET_CAMERAS_SUCCESS: 'GET_CAMERAS_SUCCESS',
  GET_CAMERAS_ERR: 'GET_CAMERAS_ERR',
  getCamerasStart: () => {
    return {
      type: actions.GET_CAMERAS_START,
    };
  },

  getCamerasErr: (data) => {
    return {
      type: actions.GET_CAMERAS_ERR,
      data,
    };
  },
  getCamerasSuccess: (data) => {
    return {
      type: actions.GET_CAMERAS_SUCCESS,
      data,
    };
  },
};

export default actions;
