import { useEffect, useRef } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export function ResponseInterceptor() {
  const api = axios.create({
    baseURL: 'https://backend.bizdin.ai',
  });

  const navigate = useNavigate();
  const interceptorId = useRef(null);

  useEffect(() => {
    interceptorId.current = api.interceptors.response.use(undefined, (error) => {
      switch (error.response.status) {
        case 401:
          Cookies.remove('access_token');
          Cookies.remove('logedIn');
          Cookies.remove('user');
          navigate('/');
          break;
        default:
          return Promise.reject(error);
      }
    });

    return () => {
      if (interceptorId.current) {
        api.interceptors.response.eject(interceptorId.current);
      }
    };
  }, [navigate]);

  return null;
}
