// src/getKvsStream.js
import AWS from 'aws-sdk';
import { AWS_CONFIG } from '../aws-config';

AWS.config.update(AWS_CONFIG);

const kinesisVideo = new AWS.KinesisVideo();

export const getKvsStreamUrl = async (streamName) => {
  try {
    const getDataEndpointResponse = await kinesisVideo
      .getDataEndpoint({
        StreamName: streamName,
        APIName: 'GET_HLS_STREAMING_SESSION_URL',
      })
      .promise();

    const kinesisVideoArchivedContent = new AWS.KinesisVideoArchivedMedia({
      endpoint: getDataEndpointResponse.DataEndpoint,
      ...AWS_CONFIG,
    });

    const hlsStreamResponse = await kinesisVideoArchivedContent
      .getHLSStreamingSessionURL({
        StreamName: streamName,
        PlaybackMode: 'LIVE',
      })
      .promise();

    return hlsStreamResponse.HLSStreamingSessionURL;
  } catch (error) {
    console.error('Error retrieving KVS stream URL', error);
    throw error;
  }
};
