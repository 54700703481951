import actions from './actions';

const { GET_CAMERAS_SUCCESS, GET_CAMERAS_ERR, GET_CAMERAS_START } = actions;

const initState = {
  cameras: [],
  loading: false,
  error: null,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const camerasReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_CAMERAS_START:
      return {
        ...state,
        loading: true,
      };
    case GET_CAMERAS_SUCCESS:
      return {
        ...state,
        cameras: data,
        loading: false,
      };
    case GET_CAMERAS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default camerasReducer;
