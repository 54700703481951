import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Row, Col, Skeleton, message } from 'antd';
import UilAngleLeftB from '@iconscout/react-unicons/icons/uil-angle-left-b';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main } from '../styled';
import { Cards } from '../../components/cards/frame/cards-frame';
import CameraStream from '../../components/cameraStream/CameraStream';

function CameraDetails() {
  const { id } = useParams();
  const [camera, setCamera] = useState(null);
  const [loading, setLoading] = useState(true);

  const PageRoutes = [
    {
      path: 'index',
      breadcrumbName: 'Dashboard',
    },
    {
      path: '',
      breadcrumbName: 'Cameras Page',
    },
  ];

  useEffect(() => {
    const fetchCameraDetails = async () => {
      try {
        const token = Cookies.get('access_token');
        const response = await axios.get(`https://backend.bizdin.ai/api/core/cameras/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setCamera(response.data);
      } catch (error) {
        console.error('Error fetching camera details:', error);
        message.error('An error occurred while fetching camera details.');
      } finally {
        setLoading(false);
      }
    };

    fetchCameraDetails();
  }, [id]);

  if (loading) {
    return (
      <>
        <PageHeader className="ninjadash-page-header-main" title="Cameras" routes={PageRoutes} />
        <Main>
          <Link
            to="/admin/pages/cameras"
            style={{ display: 'flex', alignItems: 'center', color: 'white', paddingBottom: '20px' }}
          >
            <UilAngleLeftB />
            <div style={{ marginLeft: '10px' }}>Back</div>
          </Link>
          <Row gutter={25}>
            <Col sm={24} xs={24}>
              <Cards headless>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '10px 15px',
                    minHeight: '100vh',
                  }}
                >
                  {/* Skeleton для заголовка */}
                  <Skeleton.Input active size="large" style={{ width: '200px', marginBottom: '20px' }} />

                  {/* Skeleton для превью камеры */}
                  <Skeleton.Image active style={{ width: '100%', height: '400px', marginBottom: '20px' }} />

                  {/* Skeleton для текста и описания камеры */}
                  <Skeleton active paragraph={{ rows: 4 }} title={false} />
                </div>
              </Cards>
            </Col>
          </Row>
        </Main>
      </>
    );
  }

  if (!camera) {
    return <div>Error: Camera not found</div>;
  }

  return (
    <>
      <div className="flex flex-row justify-between mx-5">
        <Link
          to="/admin/pages/cameras"
          className="basis-1 pt-5"
          style={{ display: 'flex', alignItems: 'center', color: 'white', paddingBottom: '20px' }}
        >
          <UilAngleLeftB />
          <div style={{ marginLeft: '10px' }}>Back</div>
        </Link>
        <PageHeader className="ninjadash-page-header-main w-full flex justify-between" title="" routes={PageRoutes} />
      </div>

      <Main>
        <Row gutter={25}>
          <Col sm={24} xs={24}>
            <Cards headless>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '10px 15px',
                  minHeight: '100vh',
                }}
              >
                <h2 style={{ color: 'white' }}>
                  Camera Name: <b>{camera.name}</b>
                </h2>
                <CameraStream hlsUrl={camera.hls_url} />
              </div>
            </Cards>
          </Col>
        </Row>
      </Main>
    </>
  );
}

export default CameraDetails;
