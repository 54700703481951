import Cookies from 'js-cookie';
// import axios from 'axios';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';

const { loginBegin, loginSuccess, loginErr, logoutBegin, logoutSuccess, logoutErr } = actions;

const login = (values, callback) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      const response = await DataService.post('/auth/login/', values);
      console.log('response', response);
      if (response.status !== 200) {
        dispatch(loginErr(response.data.errors));
      } else {
        Cookies.set('access_token', response.data.access);
        Cookies.set('logedIn', true);
        Cookies.set('user', JSON.stringify(response.data.user));
        dispatch(loginSuccess(response.data.user));
        callback();
      }
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const authOLogin = (values, callback) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    Cookies.set('access_token', values.accessToken);
    Cookies.set('logedIn', true);
    dispatch(loginSuccess(true));
    callback();
  };
};

const register = (values) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      const response = await DataService.post('auth/registration/', values);
      if (response.data.errors) {
        dispatch(loginErr('Registration failed!'));
      } else {
        Cookies.set('access_token', response.data.access);
        Cookies.set('logedIn', true);
        dispatch(loginSuccess(response.data.user));
      }
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const logOut = (callback) => {
  return async (dispatch) => {
    dispatch(logoutBegin());
    try {
      Cookies.remove('logedIn');
      Cookies.remove('access_token');
      Cookies.remove('user');
      dispatch(logoutSuccess(false));
      callback();
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

export { login, authOLogin, logOut, register };
